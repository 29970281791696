.flex-card {
    // background-image:url("https://cdn.pixabay.com/photo/2018/01/25/14/15/turkey-3106216__340.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    vertical-align: middle;

    // display: flex;
    // flex-direction: column;
    // justify-content: center;

    // width: 100%;
    // border-width: 5px;
    border-width: var(--lp-border-width);
    border-style: solid;

    // padding-top: 10px;
    // margin-left: 10px;
    // margin-right: 5px;
    // margin-bottom: 2px;
    // margin-top: 2px;
    border-radius: var(--border-radius-global);

    // border-radius: 10px;
    position: relative;
    cursor: pointer;

    // text-shadow:
    // -1px -1px 0 #000,
    // 1px -1px 0 #000,
    // -1px 1px 0 #000,
    // 1px 1px 0 #000;
}

// .flex-card ion-col {
//     padding: 0px;
// }

.flex-card-grid-no-padding ion-col {
    padding: 0px;
}

.story-card {
    height: 200px !important;
}

.story-card-small {
    height: 180px !important;
}

.story-card-large {
    height: 240px !important;
}

.story-card-locked {
    opacity: 0.5;
}

.item-card {
    height: 140px !important;
}

.item-card-small {
    height: 70px !important;
}

.category-card {
    height: 120px !important;
}

.place-card {
    min-height: 120px !important;
    .content {
        // height: 98px !important;
        min-height: 110px !important;
    }
}

.place-card-exp {
    min-height: 140px !important;
    .content {
        // height: 128px !important;
        min-height: 130px !important;
    }
}

.place-card-align-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.leader-card {
    height: 140px !important;

    .padding-title {
        padding-bottom: 16px !important;
    }
}

.leader-card-v2 {
    // height: 168px !important;
    // height: 190px !important;
}

.leader-card-selected-v2 {
    // height: 160px !important;
    // height: 192px !important;
}

.group-role-card {
    // height: 180px !important;
}

.mp-leader-card {
    // height: 100px !important;
    height: 132px !important;

    .padding-title {
        // padding-bottom: 16px !important;
    }
}

.mp-user-card {
    height: 140px !important;

    .padding-title {
        padding-bottom: 8px !important;
    }
}

.group-card {
    height: 140px !important;
}

.place-ads-card {
    height: 180px !important;
}

.card-selected {
    border-width: var(--lp-border-width);
    border-style: solid;
    border-color: #{rgba($lp-color-text-3, 0.5) !important};
    // border-radius: var(--border-radius-inner-global);
    border-radius: var(--border-radius-inner-overlay);
    padding-top: 5px;
    padding-bottom: 5px;
}

.card-disabled {
    opacity: 0.5;
}

.ngb-slider-img-padding {
    padding-bottom: 40px;
}

.wrap-img-container-wrapper {
    img {
        margin-top: 6px;
        position: relative;
        display: inline-block;
    }
}

.wrap-img-container {
    margin-top: 6px;
    position: relative;
    display: inline-block;
}

.wrap-img-button {
    background-image: url("../../assets/icons/zoom.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // background-color: rgba(0, 0, 0, 0);
    background-color: #fcd182cc;
    border-radius: 50%;
    position: absolute;
    top: 9px;
    right: 9px;
    width: 36px;
    height: 36px;
    z-index: 1;
}
