.overlay-header {
    padding-top: var(--leplace-status-bar-height);
}

.overlay-header-ios {
    padding-top: var(--leplace-status-bar-height-ios);
}

.overlay-header-web {
    padding-top: var(--leplace-status-bar-height-web);
}

.list-header {
    min-height: 0px !important;
}
