.center-highlight {
    // background: #{radial-gradient(rgba($lp-color-action-3, 0.2), rgba($lp-color-primary-card, 0.9))};
    // background: #{linear-gradient(rgba($lp-color-action-3, 0.7), rgba($lp-color-action-3, 0.2))};
    border-radius: 50%;
    padding: 22px;
    width: 220px;

    // width: 70vw;
    height: 220px;

    // height: 70vw;
    max-width: 70vw;
    max-height: 70vw;
    border-style: inset;
    border-width: 6px;
    border-color: var(--lp-color-primary-70);

    background: #{linear-gradient(rgba($lp-color-primary-card, 0.7), rgba($lp-color-primary-card, 0.2))};

    // border-color: var(--lp-border-color);
    margin: auto;
}

.center-highlight-animation {
    -webkit-transition: background-color 700ms ease-in-out;
    -ms-transition: background-color 700ms ease-in-out;
    transition: background-color 700ms ease-in-out;
}

.center-highlight-primary {
    // background: #{linear-gradient(rgba($lp-color-primary-card, 0.7), rgba($lp-color-primary-card, 0.2))};
    // background-color: rgba($lp-color-primary-card, 0.7);
    // border-color: var(--lp-color-primary-70);
}

.center-highlight-alternate {
    // background: #{linear-gradient(rgba($lp-color-alternate-card, 0.2), rgba($lp-color-alternate-card, 0.7))};
    // background-color: rgba($lp-color-alternate-card, 0.7);
    // border-color: var(--lp-color-primary);
}

.full-highlight {
    background: linear-gradient(var(--lp-color-primary-70), var(--lp-color-primary-20));
}

.plate-img {
    max-height: 80px;
    max-width: 100%;
    transform: scale(1);
    border: 0;
    transition: transform 250ms ease-in-out;
}

.plate-img-highlight {
    transform: scale(1.1);
}
