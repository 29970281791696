.back-button-width {
    width: 50px;
}

.toolbar-button {
    width: 80px;
}

.toolbar-blank {
    height: 4px;
}

.custom-searchbar .searchbar-input {
    border-radius: 5px !important;
}

.inner-toolbar {
    padding: 2px !important;
    min-height: 32px !important;
}

.rounded-toolbar {
    border-bottom-left-radius: var(--border-radius-global);
    border-bottom-right-radius: var(--border-radius-global);
}

.toolbar-style {
    padding-top: 0px !important;
}

.toolbar-inline {
    --background: var(--lp-color-negative-bg);
}

.toolbar-md,
.toolbar-ios {
    min-height: 40px;
    padding: 0px;
    padding-left: 1px;
    padding-right: 1px;
}

.paginator-bar-height {
    // height: 50px;
    height: 48px;
    // padding-bottom: 4px;
}