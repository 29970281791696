.no-scroll {
    overflow-y: hidden !important;
}

.menu-scroll {
    height: 100%;
    overflow-y: auto;
}

.scroll-content-no-scroll {
    .scroll-content {
        overflow-y: hidden !important;
    }
}

.scroll-content {
    overflow-y: auto !important;
}

.scrollbar-y-hidden {
    overflow-y: hidden !important;
    overflow-x: hidden;
}

.scrollbar-y {
    overflow-y: auto !important;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    // display: none;
}

// used to fill height with bottom space for buttons
.scroll-height-btn-2 {
    height: calc(100vh - 100px);
}

// used to fill height with bottom space for buttons
.scroll-height-btn-1 {
    height: calc(100vh - 100px);
}

.scroll-height-btn-1-border {
    height: calc(100vh - 240px);
}

// used for modals that are not full screen
.scroll-height-btn-1-console-border {
    height: calc(80vh - 150px);
}

.scroll-type-2-top {
    overflow-y: auto !important;
    overflow-x: hidden;
    position: absolute !important;
    top: 0px !important;
    bottom: 55px !important;
    left: 0 !important;
    right: 0 !important;
    -webkit-overflow-scrolling: touch;
    margin-top: 0px !important;

    // -webkit-overflow-scrolling: touch;
    // display: none;
}

.scroll-type-2 {
    overflow-y: auto !important;
    overflow-x: hidden;
    position: absolute !important;
    top: 41px !important;
    bottom: 55px !important;
    left: 0 !important;
    right: 0 !important;
    -webkit-overflow-scrolling: touch;
    margin-top: 0px !important;

    // -webkit-overflow-scrolling: touch;
    // display: none;
}

.scroll-type-2xb {
    overflow-y: auto !important;
    overflow-x: hidden;
    position: absolute !important;
    top: 70px !important;
    bottom: 98px !important;
    left: 0 !important;
    right: 0 !important;
    -webkit-overflow-scrolling: touch;
    margin-top: 0px !important;

    // -webkit-overflow-scrolling: touch;
    // display: none;
}

.scroll-type-2xt {
    overflow-y: auto !important;
    overflow-x: hidden;
    position: absolute !important;
    top: 120px !important;
    bottom: 71px !important;
    left: 0 !important;
    right: 0 !important;
    -webkit-overflow-scrolling: touch;
    margin-top: 0px !important;

    // -webkit-overflow-scrolling: touch;
    // display: none;
}

.scroll-type-3 {
    overflow-y: auto !important;
    overflow-x: hidden;
    position: absolute !important;
    top: 65px !important;
    bottom: 55px !important;
    left: 0 !important;
    right: 0 !important;
    -webkit-overflow-scrolling: touch;
    margin-top: 0px !important;
    // -webkit-overflow-scrolling: touch;
    // display: none;
}

.scroll-type-frame-list {
    overflow-y: auto !important;
    overflow-x: hidden;
    position: absolute !important;
    top: 0px !important;
    bottom: 60px !important;
    left: 0 !important;
    right: 0 !important;
    -webkit-overflow-scrolling: touch;
}

.scroll-content-modal-1 {
    position: absolute !important;
    top: 0px !important;
    left: 10px !important;
    right: 10px !important;
    bottom: 72px !important;
}

.scroll-content-modal-1-wide {
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 72px !important;
}

.scroll-content-modal-1-flush {
    position: absolute !important;
    top: 0px !important;
    left: 10px !important;
    right: 10px !important;
    bottom: 0px !important;
}

.scroll-content-modal-1-border {
    position: absolute !important;
    top: 10px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 80px !important;
}

.scroll-content-modal-1-border-flush {
    position: absolute !important;
    top: 0px !important;
    // left: 10px !important;
    // right: 10px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 66px !important;
}

.scroll-content-modal-1-border-flush-fs {
    position: absolute !important;
    top: 0px !important;
    // left: 10px !important;
    // right: 10px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 86px !important;
}

.scroll-content-modal-2 {
    position: absolute !important;
    top: 10px !important;
    left: 10px !important;
    right: 10px !important;
    bottom: 105px !important;
}

.scroll-content-modal-2-border {
    position: absolute !important;
    top: 10px !important;
    // left: 10px !important;
    // right: 10px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 120px !important;
}

::-webkit-scrollbar {
    // width: 12px;
    -webkit-overflow-scrolling: touch;
    display: none;

    // height: 5px;
}

.scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    .li {
        flex: 0 0 auto;
    }
}

.scroll-anchor-container {
    // height: 300px;
    // overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

.scroll-anchor-container .scroll-anchor-element {
    scroll-snap-align: start;
}

.scroll-offset {
    scroll-margin: 300px;
}

.scroll-padding {
    // scroll-padding-top: -200px;
    // margin-bottom: 250px !important;
    margin-bottom: 30vh !important;
}

// .scroll-padding-large {
//     // scroll-padding-top: -200px;
//     // margin-bottom: 250px !important;
//     margin-bottom: 40vh !important;
// }

.scroll-into-view-container {
}
