.description-frame {
    p {
        margin-bottom: 3px;
        margin-top: 3px;
        min-height: 0.7em;
        // color: var(--lp-color-light);
    }

    a {
        color: var(--lp-color-primary-text) !important;
    }
}

.description-frame-p {
    p {
        min-height: 0.7em;
        color: var(--lp-color-primary-text) !important;
    }
}

.rating-view {
    font-size: 10px;

    .rating {
        font-size: 10px;
    }

    .star-icon {
        font-size: 10px;
    }
}

.rating-input {
    font-size: 20px;

    .rating {
        font-size: 20px;
    }

    .star-icon {
        font-size: 20px;
    }
}

.leplace-input-border {
    border-color: var(--lp-color-primary-item);
    border-style: solid;
    border-width: 2px;
    border-radius: 14px;
}

.leplace-textarea-padding {
    --padding-top: 10px;
    --padding-end: 8px;
    --padding-bottom: 11px;
    --padding-start: 8px;
}

.leplace-textarea {
}

.leplace-textarea .text-input {
    //background: var(--lp-color-primary-text);
    //border-style: inset;
    border-radius: 4px;
    border-width: 1px;
    font-style: italic;
    display: block;
    padding: 5px;

    //border-color: var(--lp-color-primary);
    // color: var(--lp-color-light);
    color: var(--lp-color-light);
}

.leplace-textarea-chat {
}

.leplace-textarea-chat .text-input {
    display: block;

    // padding: 5px;
}
