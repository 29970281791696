.flex-container {
    display: flex;
}

// .flex-container > div {
//     background-color: #f1f1f1;
//     margin: 10px;
//     padding: 20px;
//     font-size: 30px;
// }

.flex-container .flex-element {
    margin-left: 10px;
    // margin: 10px;
    // padding: 20px;
    // font-size: 30px;
}
