:root {
    --shadow-color: rgba(0, 0, 0, 0.4);
    --custom-backdrop-transition: 1s;
}

.modal-transparent {
    // --background: rgba(0,0,0,0);
    --background: var(--lp-transparent-modal-bg);
}

.modal-small-height {
    height: calc(60vh - 60px);
}

.modal-small-height-max {
    max-height: calc(60vh - 60px);
}

.modal-large-height {
    height: calc(80vh - 60px);
}

.modal-large-height-inner {
    height: calc(80vh - 64px);
}

.modal-center,
.modal-center-large,
.modal-center-small {
    &::part(content) {
        position: absolute;
        overflow: hidden;

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        // border: 0;
        // border-width: 3px;
        box-shadow: 0px 16px 20px 0px var(--shadow-color);
        -webkit-box-shadow: 0px 16px 20px 0px var(--shadow-color);
        border-style: solid;
        border-color: var(--lp-border-color);
        border-width: var(--lp-border-width);
        border-radius: var(--border-radius-global);
        //background-color: #fafafa;
    }
    &::part(backdrop) {
    }
}

.modal-center {
    &::part(content) {
        width: 80vw !important; //
        height: 60vh !important; // this for fixing button not showing in landscape mode
        max-height: 60vh !important; //
    }
}

.modal-center-small {
    &::part(content) {
        width: 80vw !important; //
        height: 120px !important;
        max-height: 40vh !important; //
    }
}

.modal-center-large {
    &::part(content) {
        width: 90vw !important; //
        height: 80vh !important; // this for fixing button not showing in landscape mode
        max-height: 80vh !important; //
    }
}

.modal-center,
.modal-center-large,
.modal-center-small {
    &::part(backdrop) {
        visibility: visible !important;
        opacity: 0.5;

        // filter: blur(100px);
        // transition: background-color .2s;
        // background: url();
    }
}

.modal-full {
    &::part(content) {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.modal-fullscreen {
    --width: 100%;
    --height: 100%;
}

.main-menu ion-backdrop {
    opacity: 0.9;

    // top: 15%;
    // height: 70%;
}

// @media not all and (min-height: 600px) and (min-width: 768px) {
// // @media not all and (min-height: 0px) and (min-width: 0px) {
//     ion-modal ion-backdrop {
//         visibility: hidden;
//     }
// }

// @media only screen and (min-height: 0px) and (min-width: 0px) {
//     .modal-wrapper {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//     }
// }

.custom-backdrop {
    filter: blur(5px);
    transition: all var(--custom-backdrop-transition);
    -webkit-transition: all var(--custom-backdrop-transition);
    -moz-transition: all var(--custom-backdrop-transition);
  
    // ::after {
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    //     // background: black;
    //     opacity: 0.1;
    //     // display: block;
    //     box-shadow: inset 0px 0px 400px 110px rgba(0, 0, 0, .7);
    // }
}

.custom-backdrop-none {
    filter: blur(0);
    transition: all var(--custom-backdrop-transition);
    -webkit-transition: all var(--custom-backdrop-transition);
    -moz-transition: all var(--custom-backdrop-transition);
}

.custom-modal-style {
    background: rgba(0, 0, 0, 0);
    --background: rgba(0, 0, 0, 0);

    .scroll-content {
        // background: var(--background-radial-gradient-matte);
        // background: var(--background-radial-gradient);
    }

    // ::after {
    //     background: var(--background-radial-gradient);
    // }
}

.modal-background {
    // .scroll-content {
    //     background: var(--lp-color-bg));
    // }
    // background: var(--lp-color-negative-bg));
    background: var(--lp-color-bg);
    --background: var(--lp-color-bg);
}

.modal-background-gradient {
    background: radial-gradient(var(--lp-color-negative-bg), var(--lp-color-negative-bg), var(--lp-color-bg));
    --background: radial-gradient(var(--lp-color-negative-bg), var(--lp-color-negative-bg), var(--lp-color-bg));
}

.modal-background-fixed-content-gradient {
    .fixed-content {
        background: #{radial-gradient($lp-color-negative-bg, $lp-color-negative-bg, $lp-color-bg)};
    }
}

.toolbar-background-transparent {
    --background: rgba(0, 0, 0, 0);
}

.toolbar-background-primary {
    // --background: var(--lp-color-bg);
    // border-bottom: var(--lp-border-width) solid var(--lp-color-primary-item);
}

.custom-modal-header {
    background: #{linear-gradient(to right, rgba($lp-color-bg, 0.6), rgba($lp-color-bg, 0.4), rgba($lp-color-bg, 0.6))
        !important};
}

.custom-modal-header-2 {
    background: #{linear-gradient(to right, $lp-color-bg, $lp-color-negative-bg, $lp-color-bg) !important};
}

.custom-modal-style-chat {
    background: rgba(0, 0, 0, 0);
}

.custom-select {
    .select-text {
        color: var(--lp-color-primary-text) !important;
    }

    .select-icon {
        .select-icon-inner {
            color: var(--lp-color-primary-text) !important;
        }
    }

    // height: auto;
    // padding: 0px;
    // display: flex;
    // border: none;
    // color: var(--lp-color-primary-text) !important;
    // background: rgba(0, 0, 0, 0) !important;
}

.modal-button {
    font-size: 16px;
}

.modal-title {
    font-weight: bold;
    font-size: 22px;
}

.component-slide .slide-zoom {
    height: 100%;
}
