.list-item-divider {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0px;
    z-index: 10000 !important;
    margin-top: 10px;
}

.settings-item {
    min-height: 20px !important;
    height: 40px;
}

.quiz-item {
    min-height: 48px !important;
}

.list-item-padding {}

.list-item-card-padding {
    margin-top: var(--lp-item-padding);
    --inner-padding-end: var(--lp-item-padding);
    --padding-start: 0px;
}