.image-frame {
    // border-radius: var(--border-radius-global);
    opacity: 0.7;

    // box-shadow: 2px 2px #888888;
    max-height: 300px;
    max-width: 500px;
    height: auto;
    width: auto;
    border-style: solid;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-global);
    border-width: var(--lp-border-width);
    --border-style: solid;
    --border-color: var(--lp-border-color-item);
    --border-width: var(--lp-border-width);
    --border-radius: var(--border-radius-global);
}

.item-frame {
    border-width: 5px !important;
    border-color: white;
}

.photo-frame {
    border-radius: var(--border-radius-global);
    border-style: solid;
    border-width: var(--lp-border-width);
    border-color: var(--lp-border-color-item);
    --border-style: solid;
    --border-color: var(--lp-border-color-item);
    --border-width: var(--lp-border-width);
    --border-radius: var(--border-radius-global);
}

.video-frame {
    border-radius: var(--border-radius-global);
    border-style: solid;
    border-width: var(--lp-border-width);
    border-color: var(--lp-border-color-item);
    --border-style: solid;
    --border-color: var(--lp-border-color-item);
    --border-width: var(--lp-border-width);
    --border-radius: var(--border-radius-global);
}

.frame-inset {
    border-style: inset;
    border-radius: var(--border-radius-global);
    border-width: var(--lp-border-width);
    --border-style: inset;
    --border-radius: var(--border-radius-global);
    --border-width: var(--lp-border-width);
}

.view-frame-border {
    //padding: 10px;
    margin: 10px;
    z-index: 999999999;
    zoom: 1;
    border-radius: var(--border-radius-global);

    // overflow-y: hidden;
    border-style: solid;
    border-width: var(--lp-border-width);
    border-color: var(--lp-border-color);
    --border-style: solid;
    --border-color: var(--lp-border-color);
    --border-width: var(--lp-border-width);
    --border-radius: var(--border-radius-global);
}

.view-frame-minimal {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    // margin-top: 10px;
    // margin-left: 5px;
    // margin-right: 5px;
    // margin-bottom: 10px;
    z-index: 999999999;
    zoom: 1;

    // extract to separate class
    // margin: 10px;
}

.view-frame {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 4px;
    margin-left: 8px;
    margin-right: 8px;

    // margin-bottom: 8px;
    margin-bottom: 20px;
    z-index: 999999999;
    border-radius: var(--border-radius-global);
    zoom: 1;
    border-style: solid;
    border-color: var(--lp-border-color);
    border-width: var(--lp-border-width);
    --border-style: solid;
    --border-color: var(--lp-border-color);
    --border-width: var(--lp-border-width);
    --border-radius: var(--border-radius-global);

    .bg-container {
        // border-radius: var(--border-radius-inner-global);
    }
}

.item-border-radius {
    border-radius: var(--border-radius-outer-global) !important;
}

.view-frame-small {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    position: relative;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999999;
    zoom: 1;
    border-radius: var(--border-radius-global);
    border-style: solid;
    border-color: var(--lp-border-color);
    border-width: var(--lp-border-width);
    --border-style: solid;
    --border-color: var(--lp-border-color);
    --border-width: var(--lp-border-width);
    --border-radius: var(--border-radius-global);
}

.view-frame-no-border {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    z-index: 999999999;
}

.box-container-fixed {
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 1;
    font-size: 16px;
    padding: 16px;
    z-index: 999999999;
    zoom: 1;
    border-radius: var(--border-radius-global);
    border-style: solid;
    border-color: var(--lp-border-color);
    border-width: var(--lp-border-width);
    --border-style: solid;

    // --border-color: var(--lp-border-color);
    --border-width: var(--lp-border-width);
    --border-radius: var(--border-radius-global);
}

.scroll-frame {
    border-style: solid;
    border-width: var(--lp-border-width);
    border-color: var(--lp-border-color);
    border-radius: var(--border-radius-global);
    --border-style: solid;

    // --border-color: var(--lp-border-color);
    --border-width: var(--lp-border-width);
    --border-radius: var(--border-radius-global);
    -webkit-overflow-scrolling: touch;
}

.text-frame-card {
    border-left-style: solid;
    border-right-style: solid;
    border-width: var(--lp-border-width);
    border-color: var(--lp-color-primary-item);

    // border-color: var(--lp-border-color);
    // border-radius: var(--border-radius-global-s);
    border-radius: 8px;
    padding: 8px;
}

.leplace-textarea-frame {
    border-style: solid;
    border-width: var(--lp-border-width);
    border-color: var(--lp-color-primary-item);
    border-radius: 8px;

    // padding: 6px;
    margin: 6px;

    .leplace-textarea {
        margin-top: 0px;
        padding-left: 6px;
        padding-right: 6px;
    }
}

.content-height-button-frame {
    height: calc(100% - 80px);
    // height: calc(100% - var(--action-button-height));
}

.content-height {
    height: 100% !important;
}

.content-height-iosx {
    height: 80% !important;
}

.canvas-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.responsive-canvas {
    max-width: 100%;
    max-height: 100%;
    // border: 1px solid black;
    background: var(--lp-color-primary-item);
    padding: 10px;
    border-radius: var(--border-radius-global);
}

@media all and (orientation: portrait) {
    /* Style adjustments for portrait mode goes here */
    .web-content {
    }

    .web-content-modal {
    }

    .center-frame-element {
    }
}

@media all and (orientation: landscape) and (max-height: 719px) {
    /* Style adjustments for portrait mode goes here */
    .web-content {
    }

    .center-frame-element {
    }

    .web-content-modal {
    }
}

@media all and (orientation: landscape) and (min-height: 720px) {
    /* Style adjustments for landscape mode goes here */
    .web-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 120vh;
        border-style: solid;
        border-color: var(--lp-color-primary-item);
        border-left-width: 2px;
        border-right-width: 2px;
        border-top-width: 0px;
        border-bottom-width: 0px;
        --height: 100%;
        --width: 100%;
    }

    .center-frame-element {
        max-width: 120vh;

        /* text-align: center; */
        /* align-items: center; */
        /* text-align: center; */
        margin-left: auto;
        margin-right: auto;
    }

    .web-content-modal {
        margin-left: auto;
        margin-right: auto;
        max-width: 120vh;
        border-style: solid;
        border-color: var(--lp-color-primary-item);
        border-left-width: 2px;
        border-right-width: 2px;
        border-top-width: 0px;
        border-bottom-width: 0px;
        // border-bottom-right-radius: 20px;
        --height: 100%;
        --width: 100%;
    }
}
