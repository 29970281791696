$footer-height: 120;
$footer-height-small: 80;

$footer-height-iosx: 140;
$footer-height-iosx-small: 100;

$footer-height-ar: 120;
$footer-height-ar-iosx: 150;

.leplace-footer-container {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.leplace-footer-iosx {
    // height: #{$footer-height * 1px} !important;
    height: #{$footer-height-iosx * 1px} !important;
    padding: 5px;
    border-top-left-radius: var(--border-radius-global);
    border-top-right-radius: var(--border-radius-global);
}

.leplace-footer-iosx-small {
    height: #{$footer-height-iosx-small * 1px} !important;
    padding: 5px;

    // margin-left: 5px;
    // margin-right: 5px;
    // left: 5px;
    // right: 5px;
    border-top-left-radius: var(--border-radius-global);
    border-top-right-radius: var(--border-radius-global);
}

.leplace-footer {
    // height: #{$footer-height * 1px} !important;
    height: #{$footer-height * 1px} !important;
    padding: 5px;
    border-top-left-radius: var(--border-radius-global);
    border-top-right-radius: var(--border-radius-global);
}

.leplace-footer-small {
    height: #{$footer-height-small * 1px} !important;
    padding: 5px;

    // margin-left: 5px;
    // margin-right: 5px;
    // left: 5px;
    // right: 5px;
    border-top-left-radius: var(--border-radius-global);
    border-top-right-radius: var(--border-radius-global);
}

.leplace-header {
    height: #{$footer-height * 1px} !important;

    //padding: 5px;

    // border-bottom-left-radius: var(--border-radius-global);
    // border-bottom-right-radius: var(--border-radius-global);
    border-radius: var(--border-radius-global);
}

.leplace-header-small {
    height: #{$footer-height-small * 1px} !important;

    //padding: 5px;

    // margin-left: 5px;
    // margin-right: 5px;
    // left: 5px;
    // right: 5px;
    // border-bottom-left-radius: var(--border-radius-global);
    // border-bottom-right-radius: var(--border-radius-global);
    left: 5px;
    right: 5px;
    top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-radius: var(--border-radius-global);
}

.banner {
    position: absolute;
    z-index: 999;
    left: 0;
    bottom: 0;
    width: 100%;
}

.margin-bottom-fab {
    margin-bottom: #{$footer-height * 1px};
}

.gmap-margin-bottom-2 {
    margin-bottom: #{($footer-height + 32 - 2) * 1px};
}

.gmap-margin-bottom {
    margin-bottom: #{($footer-height - 2) * 1px};
}

.gmap-margin-bottom-small {
    margin-bottom: #{($footer-height-small - 2) * 1px};
}

.gmap-margin-bottom-iosx {
    margin-bottom: #{($footer-height-iosx - 2) * 1px};
}

.gmap-margin-bottom-iosx-small {
    margin-bottom: #{($footer-height-iosx-small - 2) * 1px};
}

.ar-margin-bottom {
    margin-bottom: #{($footer-height-ar - 2) * 1px};
}

.ar-margin-bottom-iosx {
    margin-bottom: #{($footer-height-ar-iosx - 2) * 1px};
}

.gmap-margin-top {
    margin-top: var(--leplace-status-bar-margin-height);
}

.gmap-margin-top-fab {
    margin-top: var(--leplace-status-bar-margin-height);
}

.gmap-margin-top-ios {
    margin-top: var(--leplace-status-bar-margin-height-ios);
}

.gmap-margin-top-fab-ios {
    margin-top: var(--leplace-status-bar-margin-height-ios);
}

.gmap-margin-top-web {
    margin-top: var(--leplace-status-bar-margin-height-web);
}

.gmap-margin-top-fab-web {
    margin-top: var(--leplace-status-bar-margin-height-web);
}
