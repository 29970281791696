
.text-color {
    color: var(--lp-color-primary-text) !important;
}

.text-color-element {
    color: var(--lp-color-primary-text-element) !important;
}

.text-color-overlay {
    color: var(--lp-color-text-overlay);
    // background: #{rgba($lp-color-text-overlay-bg), 0.9) !important};
    // background: var(--text-overlay-radial-gradient);
    background: #{rgba($lp-color-filter-2, 0.9)};
}

.text-color-overlay-s {
    color: var(--lp-color-text-overlay);
    // background: var(--text-overlay-radial-gradient);
    background: #{rgba($lp-color-filter-2, 0.9)};
}

.text-color-overlay-flush {
    color: var(--lp-color-text-overlay);

    // background: var(--text-overlay-radial-gradient);

    background: #{rgba($lp-color-filter-2, 0.7)};
}

.text-color-overlay-color-faded {
    color: var(--lp-color-text-overlay);
    // background: var(--text-overlay-radial-gradient);
    background: #{rgba($lp-color-filter-2, 0.7)};
}

// new styles, unform spec (TODO: replace old styles)


.text-color-primary-hidden {
    color: var(--lp-color-primary) !important;
}

// text@primary
.text-color-primary {
    color: var(--lp-color-primary-text) !important;
}

.text-color-primary-high {
    color: var(--lp-color-primary-text-high) !important;
}

.text-color-primary-low {
    color: var(--lp-color-primary-text-low) !important;
}

// text@alternate
.text-color-alternate {
    color: var(--lp-color-alternate-text) !important;
}

.text-color-alternate-high {
    color: var(--lp-color-alternate-text-high) !important;
}

.text-color-alternate-low {
    color: var(--lp-color-alternate-text-low) !important;
}

// text@accent
.text-color-accent {
    color: var(--lp-color-accent-text) !important;
}

.text-color-accent-contrast {
    color: var(--lp-color-accent) !important;
}

.text-color-accent-high {
    color: var(--lp-color-accent-text-high) !important;
}

.text-color-accent-low {
    color: var(--lp-color-accent-text-low) !important;
}



// text@warn
.text-color-warn {
    color: var(--lp-color-warn-text) !important;
}

.text-color-warn-contrast {
    color: var(--lp-color-warn) !important;
}

.text-color-warn-high {
    color: var(--lp-color-warn-text-high) !important;
}

.text-color-warn-low {
    color: var(--lp-color-warn-text-low) !important;
}


// other

.text-color-primary-chat {
    color: var(--lp-color-primary-text-chat) !important;
}

.text-color-primary-chat-low {
    color: var(--lp-color-primary-text-chat-low) !important;
}