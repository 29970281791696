// https://paper-leaf.com/insights/creating-blurred-background-using-only-css/

.content-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    /* Enable GPU Rendering */
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}

.content-blur {
    /* All the background image supports */
    transform: translate(0px, 0px, 0px);
    background-image: -webkit-linear-gradient(bottom left, rgba(43, 40, 50, 0.8) 0%, rgba(83, 86, 99, 0.8) 45%, rgba(69, 77, 91, 0.6) 60%);
    background-image: -moz-linear-gradient(bottom left, rgba(43, 40, 50, 0.8) 0%, rgba(83, 86, 99, 0.8) 45%, rgba(69, 77, 91, 0.6) 60%);
    background-image: -o-linear-gradient(bottom left, rgba(43, 40, 50, 0.8) 0%, rgba(83, 86, 99, 0.8) 45%, rgba(69, 77, 91, 0.6) 60%);
    background-image: linear-gradient(to top right, rgba(43, 40, 50, 0.8) 0%, rgba(83, 86, 99, 0.8) 45%, rgba(69, 77, 91, 0.6) 60%);
    position: absolute;
    width: 200%;
    height: 200%;

    /* blur filters */
    -webkit-filter: blur(20px);
    -o-filter: blur(20px);
    filter: blur(20px);

    /* positioning and removing edges */
    top: -70px;
    right: -70px;
    left: -70px;
    padding: 70px;
}

.content-blur:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1001;

    /* Setting the gradient. Adds depth, and glass like effect. */
    background-image: -webkit-linear-gradient(289deg, rgba(43, 40, 50, 0.8) 0%, rgba(43, 40, 50, 0.4) 46%, rgba(0, 0, 0, 0.05) 50%);
    background-image: -moz-linear-gradient(289deg, rgba(43, 40, 50, 0.8) 0%, rgba(43, 40, 50, 0.4) 46%, rgba(0, 0, 0, 0.05) 50%);
    background-image: -o-linear-gradient(289deg, rgba(43, 40, 50, 0.8) 0%, rgba(43, 40, 50, 0.4) 46%, rgba(0, 0, 0, 0.05) 50%);
    background-image: linear-gradient(-19deg, rgba(43, 40, 50, 0.8) 0%, rgba(43, 40, 50, 0.4) 46%, rgba(0, 0, 0, 0.05) 50%);

    /* Blur to add depth. High to create smooth transition */
    -webkit-filter: blur(70px);
    -o-filter: blur(70px);
    filter: blur(70px);
}


.content-img {
    // background-image: url("assets/img/photos/bg3.jpg");
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-filter: blur(70px);
    -o-filter: blur(70px);
    filter: blur(70px);
    // filter: progid: DXImageTransform.Microsoft.Blur(PixelRadius='70');
    background-size: cover;
  }
