.progress-bar-bg {
    background-color: var(--lp-color-primary);
    width: auto;
    height: 80%;
    border-radius: 5px;
}

.progress-bar {
    background-color: var(--lp-color-light);
    height: 100%;
    border-radius: 5px;
}

.progress-size {
    width: 100px;
    height: 20px;
}

.progress-label {
    font-size: 2.7vw;
    position: absolute;
    text-align: center;
    top: 0.12vw;
    left: 50%;
    transform: translate(-50%, 0);
}

.progress-bar-alternate {
    .mat-progress-bar .mat-progress-bar-fill::after {
        // background-color: var(--lp-color-primary-item) !important;
        background-color: var(--lp-color-alternate) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar .mat-progress-bar-fill::after {
        // background-color: var(--lp-color-primary-item) !important;
        background-color: var(--lp-color-alternate) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar-fill::after {
        background-color: var(--lp-color-alternate) !important;

        // background-color: var(--lp-color-primary-item) !important;
   
    }
}

.progress-bar-alternate-light {
    .mat-progress-bar .mat-progress-bar-fill::after {
        // background-color: var(--lp-color-primary-item) !important;
        background-color: var(--lp-color-alternate-70) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar .mat-progress-bar-fill::after {
        // background-color: var(--lp-color-primary-item) !important;
        background-color: var(--lp-color-alternate-70) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar-fill::after {
        background-color: var(--lp-color-alternate-70) !important;

        // background-color: var(--lp-color-primary-item) !important;
   
    }

    .mat-progress-bar-background {
        fill: rgba(0, 0, 0, 0);
    }

    .mat-progress-bar-buffer {
        background-color: rgba(0, 0, 0, 0.2);
    }

    // mat-progress-bar-overlay
}

.progress-bar-accent {
    .mat-progress-bar .mat-progress-bar-fill::after {
        background-color: var(--lp-color-accent) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar .mat-progress-bar-fill::after {
        background-color: var(--lp-color-accent) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar-fill::after {
        background-color: var(--lp-color-accent) !important;
    }
}

.progress-bar-warn {
    .mat-progress-bar .mat-progress-bar-fill::after {
        background-color: var(--lp-color-warn) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar .mat-progress-bar-fill::after {
        background-color: var(--lp-color-warn) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar-fill::after {
        background-color: var(--lp-color-warn) !important;
    }
}

.progress-bar-primary {
    .mat-progress-bar .mat-progress-bar-fill::after {
        background-color: var(--lp-color-primary-item) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar .mat-progress-bar-fill::after {
        background-color: var(--lp-color-primary-item) !important;
    }

    .mat-progress-bar-loading .mat-progress-bar-fill::after {
        background-color: var(--lp-color-primary-item) !important;
    }
}

.multistep-color-primary {
    .container {
        .progress-bar-multi {
            li {
                &::before {
                    content: "";
                    background-color: var(--lp-color-primary) !important;
                }
            }
        }
    }
}

.multistep-small {
    .container {
        .progress-bar-multi {
            li {
                &::before {
                    content: "";
                    width: 8px !important;
                    height: 8px !important;
                    line-height: 8px !important;
                }

                &::after {
                    content: "";
                    top: 4px !important;
                    left: -50%;
                    z-index: -1;
                }
            }
        }
    }
}

// this one is used !!!
.multistep-progress {
    $pb-step-size: 16;
    $pb-line-w: 6;
    $pb-step-border-w: 4;
    $pb-step-padding-left: 10;
    $pb-step-padding-left-content: 46;

    $pb-step-border-w-px: #{$pb-step-border-w * 1px};
    $pb-step-size-px: #{$pb-step-size * 1px};
    $pb-line-w-px: #{$pb-line-w * 1px};
    $pb-step-padding-left-content-px: #{$pb-step-padding-left-content * 1px};

    .container {
        text-align: center;
        color: var(--lp-color-cool-2);
        margin: 0 auto;
        max-width: 835px;
        width: 100%;

        .progress-bar-multi {
            display: flex;
            padding: 0;
            margin-top: 10px;
            margin-bottom: 0px;

            li {
                list-style-type: none;
                position: relative;
                text-align: center;
                color: var(--lp-color-primary-text);
                width: 100%;

                &::before {
                    content: "";

                    // content: counter(step);
                    counter-increment: step;
                    width: $pb-step-size-px;
                    height: $pb-step-size-px;
                    line-height: $pb-step-size-px;
                    border: $pb-step-border-w-px solid var(--lp-color-primary-faded);
                    display: block;
                    text-align: center;
                    margin: 0 auto 10px auto;
                    border-radius: 50%;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 5px;
                    background-color: var(--lp-color-primary-faded);
                    top: #{$pb-step-size / 2 * 1px};
                    left: -50%;
                    z-index: -1;
                }

                &:first-child::after {
                    content: none;
                }

                &.opened {
                    color: var(--lp-color-action-3);

                    &::before {
                        border-color: var(--lp-color-action-3);
                    }

                    + li::after {
                        background-color: var(--lp-color-action-3);
                    }
                }

                &.active {
                    color: var(--lp-color-cool);

                    &::before {
                        border-color: var(--lp-color-cool);
                    }

                    + li::after {
                        background-color: var(--lp-color-cool);
                    }
                }

                &.done {
                    color: var(--lp-color-action-1);

                    &::before {
                        border-color: var(--lp-color-action-1);
                    }

                    + li::after {
                        background-color: var(--lp-color-action-1);
                    }
                }
            }
        }
    }
}
