.text-color-overlay-accent {
    color: var(--lp-color-primary-text) !important;
    background: #{rgba($lp-color-accent-text, 0.9)} !important;
}

.text-color-overlay-warn {
    color: var(--lp-color-primary-text) !important;
    background: #{rgba($lp-color-warn-text, 0.9)} !important;
}

.text-color-overlay-alternate {
    color: var(--lp-color-primary-text) !important;
    background: #{rgba($lp-color-alternate-text, 0.9)} !important;
}

.item-color-overlay-alternate {
    color: var(--lp-color-alternate-text) !important;
    background: #{rgba($lp-color-alternate, 0.9)} !important;
}