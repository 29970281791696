.fab-margin-top-large {
    margin-top: 80px;
}

.fab-no-margin {
    margin: 0px;
}

.fab-margin-top-gmap-detail {
    margin-top: 80px;
    left: 0 !important;
}

.fab-margin-top-s {
    margin-top: 10px;
}

.fab-margin-bottom-gmap {
    margin-bottom: 140px;
}

.card-padding-s {
    padding: 2px !important;
}

.gmap-progress-height {
    height: 40px;
}

.mat-row-format {
    min-height: 24px !important;
}

.mat-paginator-format {
    font-size: var(--font-size-mlg) !important;
}

.mat-paginator-format .mat-select-value {
    color: white;
}
