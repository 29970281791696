// icon@primary
.icon-color-primary {
    .icon-md,
    .icon-ios {
        color: var(--lp-color-primary-icon) !important;
    }

    color: var(--lp-color-primary-icon);
    --color: var(--lp-color-primary-icon);

    path {
        color: var(--lp-color-primary-icon);
    }
}

.icon-color-primary-high {
    .icon-md,
    .icon-ios {
        color: var(--lp-color-primary-icon-high) !important;
    }

    color: var(--lp-color-primary-icon-high);
    --color: var(--lp-color-primary-icon-high);

    path {
        color: var(--lp-color-primary-icon-high);
    }
}

.icon-color-primary-low {
    .icon-md,
    .icon-ios {
        color: var(--lp-color-primary-icon-low) !important;
    }

    color: var(--lp-color-primary-icon-low);
    --color: var(--lp-color-primary-icon-low);

    path {
        color: var(--lp-color-primary-icon-low);
    }
}

// icon@alternate
.icon-color-alternate-high {
    .icon-md,
    .icon-ios {
        color: var(--lp-color-alternate-icon-high) !important;
    }

    color: var(--lp-color-alternate-icon-high);
    --color: var(--lp-color-alternate-icon-high);
}

.icon-color-alternate-low {
    .icon-md,
    .icon-ios {
        color: var(--lp-color-alternate-icon-low) !important;
    }

    color: var(--lp-alternate-icon-low);
    --color: var(--lp-alternate-icon-low);
}

.icon-color-alternate {
    .icon-md,
    .icon-ios {
        color: var(--lp-color-alternate-icon) !important;
    }

    color: var(--lp-color-alternate-icon);
    --color: var(--lp-color-alternate-icon);
}

// icon@accent
.icon-color-accent {
    .icon-md,
    .icon-ios {
        color: var(--lp-color-accent) !important;
    }

    color: var(--lp-color-accent);
    --color: var(--lp-color-accent);
}

.icon-color-accent-high {
    .icon-md,
    .icon-ios {
        color: var(--lp-color-accent-icon-high) !important;
    }

    color: var(--lp-color-accent-icon-high);
    --color: var(--lp-color-accent-icon-high);
}

.icon-color-accent-low {
    .icon-md,
    .icon-ios {
        color: var(--lp-color-accent-icon-low) !important;
    }

    color: var(--lp-color-accent-icon-low);
    --color: var(--lp-color-accent-icon-low);
}

// icon@warn
.icon-color-warn {
    .icon-md,
    .icon-ios {
        color: var(--lp-color-warn) !important;
    }

    color: var(--lp-color-warn);
    --color: var(--lp-color-warn);
}

.icon-color-warn-high {
    .icon-md,
    .icon-ios {
        color: var(--lp-color-warn-icon-high) !important;
    }

    color: var(--lp-color-warn-icon-high);
    --color: var(--lp-color-warn-icon-high);
}

.icon-color-warn-low {
    .icon-md,
    .icon-ios {
        color: var(--lp-color-warn-icon-low) !important;
    }

    color: var(--lp-color-warn-icon-low);
    --color: var(--lp-color-warn-icon-low);
}

// ion-icon {
//     pointer-events: none;
// }