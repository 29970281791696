.element-selector {
    cursor: pointer;
}

.transparent-bg {
    --background: rgba(0, 0, 0, 0) !important;
    background: rgba(0, 0, 0, 0) !important;
}

.icon-size {
    font-size: 40px;
}

.transparent {
    background-color: transparent;
}

/* Hack for ionicicons v2 (no animation code) */
.icon-spin-animation {
    -webkit-animation: spin 1.5s infinite linear;
    -moz-animation: spin 1.5s infinite linear;
    -o-animation: spin 1.5s infinite linear;
    animation: spin 1.5s infinite linear;
}

/* Hack for center text with animation */
.animate-expander {
    display: block;
    transition: all 500ms ease-in-out;
}

.animate-expander.active {}

.animate-expander-element {
    display: inline-block; // critically must be inline-block
    transition: all 500ms ease-in-out;
}

.animate-expander-element.active {}

/* text expander ext */
.animate-expander-text {}

.animate-expander-text.active {}

.animate-expander-element-text {
    font-size: var(--font-size-mlg);
}

.animate-expander-element-text.active {
    // font-size: var(--font-size-xxl);
    font-size: var(--font-size-lg);
}

/* center expander ext */

.animate-expander-center {
    transform: translateX(0%);
}

.animate-expander-center.active {
    transform: translateX(50%);
}

.animate-expander-element-center {
    transform: translateX(0%);
}

.animate-expander-element-center.active {
    transform: translateX(-50%);
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
    }
}

@-o-keyframes spin {
    0% {
        -o-transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(359deg);
    }
}

@-ms-keyframes spin {
    0% {
        -ms-transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(359deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

// .ion-load-a.icon-spin-animation {
//     -webkit-animation-timing-function: steps(8, start);
//     -moz-animation-timing-function: steps(8, start);
//     animation-timing-function: steps(8, start);
// }

.leplace-visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s linear;
}

.leplace-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
}

.widget-bg {
    // padding-left: 6px;
    // padding-right: 6px;
    // padding-top: 4px;
    // padding-bottom: 4px;

    border-radius: var(--border-radius-inner-overlay);
    border-style: solid;
    border-color: var(--lp-border-color-item);
}

.bg-transp {
    background: transparent;
    --background: transparent;
}

.icon-size {
    // font-size: 30px;
    // style="zoom:4.0;"
}

.animate-shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    // transform: translate3d(0, 0, 0);
    // backface-visibility: hidden;
    // perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}
