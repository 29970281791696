.theme-background-primary {
    background: var(--lp-color-primary) !important;
}

.widget-bg {
    background-color: #{rgba($lp-color-filter-2, 0.7)};
}

.semi-transp {
    // background: #{rgba($lp-color-primary, 0.8) !important};
    background: #{radial-gradient(rgba($lp-color-primary, 0.4), rgba($lp-color-primary, 0.8)) !important};
}

.semi-transp-thin {
    background: #{radial-gradient(rgba($lp-color-primary, 0.4), rgba($lp-color-primary, 0.8)) !important};
}

.semi-transp-uniform {
    background: #{rgba($lp-color-primary, 0.8) !important};
}

.semi-transp-uniform-warn {
    background: #{rgba($lp-color-action-3, 0.8) !important};
}

.semi-transp-uniform-error {
    background: #{rgba($lp-color-action-2, 0.8) !important};
}

.menu-button-transp {
    background: rgba(0, 0, 0, 0) !important;
    color: var(--lp-color-light);
}

.main-menu {
    background: rgba(0, 0, 0, 0) !important;

    .menu-inner {
        background: rgba(0, 0, 0, 0) !important;
        border-radius: var(--border-radius-sidebar);
    }

    .scroll-content {
        // background: var(--background-radial-gradient-menu);
        background: var(--background-linear-gradient-menu);

        // ios
        border-bottom-right-radius: var(--border-radius-sidebar);
    }

    .header-md::after,
    .header-ios::after {
        // background: #{rgba($lp-color-primary-text), 0.5);
        background: var(--lp-color-bg);

        height: 0px;
        // bottom: 0px;
    }

    .menu-header {
        background: var(--background-linear-gradient-menu);
    }

    .menu-toolbar {
        border-bottom: var(--lp-border-width) solid var(--lp-color-primary-item);
        --background: rgba(0, 0, 0, 0); // iOS
        height: 60px;
        padding-top: 16px;
    }

    .menu-content {
        border-bottom-right-radius: var(--border-radius-sidebar);
    }
}

.main-menu-drawer {
    background: rgba(0, 0, 0, 0) !important;

    .content-md,
    .content-ios {
        background: rgba(0, 0, 0, 0) !important;
        margin-top: 0px !important;
    }

    .ion-content {
        background: rgba(0, 0, 0, 0) !important;
    }
}

.main-menu-bg {
    --background: var(--background-linear-gradient-menu);
    background: var(--background-linear-gradient-menu);
}

.scroll-content {
    // background: var(--lp-color-negative-bg);
    // background: var(--background-radial-gradient);
}

.filters ion-col {
    ion-icon {
        color: var(--lp-color-primary-text) !important;
    }

    p {
        color: var(--lp-color-primary-text) !important;
    }
}

.polar-chart .pie-label-line {
    stroke: var(--lp-color-primary-text);
}

.pie-label {
    fill: var(--lp-color-primary-text);
}

.ngx-charts .gridline-path {
    stroke: var(--lp-color-primary-text) !important;
}

.polar-chart .radial-gridline-path {
    stroke: var(--lp-color-primary-text) !important;
}

.theme-card {
    background: var(--lp-color-list-item-color);
}

.theme-card-2 {
    background: var(--lp-color-primary);
}

.mat-tab-label,
.mat-tab-link {
    color: var(--lp-color-primary-text-element) !important;
}

.bg-filter-1::after {
    background: #{rgba($lp-color-filter-1, 0.7)};
    border-radius: var(--border-radius-inner-global);
}

.bg-filter-1-light::after {
    background-color: #{rgba($lp-color-filter-1, 0.4)};
    border-radius: var(--border-radius-inner-global);
}

.bg-filter-2::after {
    background-color: #{rgba($lp-color-filter-2, 0.8)};
    border-radius: var(--border-radius-inner-global);
}

.bg-filter-2-animate::after {
    -webkit-transition: background-color 1000ms ease-in-out;
    -moz-transition: background-color 1000ms ease-in-out;
    -o-transition: background-color 1000ms ease-in-out;
    -ms-transition: background-color 1000ms ease-in-out;
    transition: background-color 1000ms ease-in-out;
    background-color: #{rgba($lp-color-filter-2, 0.8)};
    border-radius: var(--border-radius-inner-global);
}

.bg-no-filter-animate::after {
    -webkit-transition: background-color 1000ms ease-in-out;
    -moz-transition: background-color 1000ms ease-in-out;
    -o-transition: background-color 1000ms ease-in-out;
    -ms-transition: background-color 1000ms ease-in-out;
    transition: background-color 1000ms ease-in-out;
    background-color: rgba(0, 0, 0, 0);
    border-radius: var(--border-radius-inner-global);
}

.bg-no-filter::after {
    background-color: rgba(0, 0, 0, 0);
    border-radius: var(--border-radius-inner-global);
}

.bg-filter-gradient-1::after {
    background-color: #{linear-gradient(135deg, rgba($lp-color-filter-1, 0.2), rgba($lp-color-filter-1, 0.7))};
    border-radius: var(--border-radius-inner-global);
}

.item-divider-md,
.item-divider-ios {
    border-color: var(--lp-border-color-item);
}

.list-item {
    // background-color: var(--lp-color-list-item-color);
    // background-color: var(--lp-color-primary-item) !important;
}

.list-md .item-block .item-inner,
.list-ios .item-block .item-inner {
    // border-color: var(--lp-color-primary-item);
    border-bottom: 0px solid var(--lp-color-primary-item);
}

.list-item-divider {
    // background-color: var(--lp-color-list-item-color);
    // background-color: var(--lp-color-primary-item-fade) !important;
    // --background: var(--lp-color-primary-item-fade) !important;
    // --background: var(--background-linear-gradient-menu) !important;
    background-color: var(--lp-color-negative-bg) !important;
    --background: var(--lp-color-negative-bg) !important;
    // --background: var(--lp-color-bg) !important;
    // border-top: var(--lp-border-width) solid;
    border-bottom: var(--lp-border-width) solid;
}

// .icon-contrast {
//     .icon-md, .icon-ios {
//         color: var(--lp-color-primary-text) !important;
//     }
// }

.leplace-textarea .text-input {
    // background: var(--lp-color-primary-text);
    border-color: var(--lp-border-color-item);
    color: var(--lp-color-light);
}

.frame-inset {
    border-color: var(--lp-border-color-item);
}

.leplace-textarea .text-input::placeholder {
    color: rgb(206, 206, 206);
}

.theme-border-top {
    // border-color: var(--lp-color-primary-item);
    border-top: var(--lp-border-width) solid var(--lp-color-primary-item);

    // border-radius: 25px;
}

.theme-border-text-top {
    border-top: var(--lp-border-width) solid var(--lp-color-primary-text);
}

.theme-border-text-bottom {
    border-bottom: var(--lp-border-width) solid var(--lp-color-primary-text);
}

.theme-border-bottom {
    border-bottom: var(--lp-border-width) solid var(--lp-color-primary-item);

    // border-radius: 25px;
}

.theme-border-bottom-thin {
    border-bottom: var(--lp-border-width-thin) solid var(--lp-color-primary-item);

    // border-radius: 25px;
}

.theme-border-bottom-negative {
    // global disabled

    // border-bottom: 2px solid var(--lp-color-primary);
    margin-bottom: 5px;

    // border-radius: 25px;
}

.theme-background {
    // background: var(--lp-color-bg) !important;

    // background: radial-gradient(#{rgba($lp-color-bg), 0.1), #{rgba($lp-color-bg), 0.1), #{rgba($lp-color-bg), 0.8)));

    background: radial-gradient(var(--lp-color-negative-bg), var(--lp-color-negative-bg), var(--lp-color-bg));

    // background: radial-gradient(var(--lp-color-primary), var(--lp-color-primary), var(--lp-color-bg));
}

.theme-background-uniform {
    background: var(--lp-color-bg) !important;
}

.theme-background-uniform-fade-out {
    transition: all 1s ease-in-out;
    background-color: #{rgba($lp-color-bg, 0)};
}

.theme-toolbar-color {
    // background: var(--lp-color-primary-item);
    // background: #{rgba($lp-color-bg), 0.6);
    background: #{linear-gradient(to right, rgba($lp-color-bg, 0.6), rgba($lp-color-bg, 0.4), rgba($lp-color-bg, 0.6))};

    .toolbar-background {
        background: #{linear-gradient(
                to right,
                rgba($lp-color-bg, 0.6),
                rgba($lp-color-bg, 0.4),
                rgba($lp-color-bg, 0.6)
            )};
        border-bottom-left-radius: var(--border-radius-global);
        border-bottom-right-radius: var(--border-radius-global);
    }

    .toolbar-background-ios {
        background: #{linear-gradient(
                to right,
                rgba($lp-color-bg, 0.6),
                rgba($lp-color-bg, 0.4),
                rgba($lp-color-bg, 0.6)
            )};
        border-bottom-left-radius: var(--border-radius-global);
        border-bottom-right-radius: var(--border-radius-global);
    }
}

.toolbar-background-md,
.toolbar-background-ios {
    // background: var(--lp-color-bg);
    // background: #{rgba($lp-color-bg), 0.6);
    background: rgba(0, 0, 0, 0);
}

ion-content {
    background: var(--lp-color-bg);
}

.location-dots {
    .progressbar li {
        color: var(--lp-color-primary-text);
    }

    .progressbar li:before {
        border: 2px solid var(--lp-color-primary);
        background-color: var(--lp-color-bg);
    }

    .progressbar li:after {
        background-color: var(--lp-color-bg);
    }

    .progressbar li.active {
        color: var(--lp-color-alternate);
    }

    .progressbar li.active:before {
        border-color: var(--lp-color-alternate);
    }

    .progressbar li.active + li:after {
        background-color: var(--lp-color-alternate);
    }
}

.multistep-progress {
    .container {
        .progress-bar-multi {
            li {
                &::before {
                    content: "";
                    background-color: var(--lp-color-filter-1) !important;
                }
            }
        }
    }
}

.leplace-textarea .text-input {
    color: var(--lp-color-light);
}

.image-frame {
    border-color: var(--lp-border-color-item);
}

.round-fab {
    border-color: var(--lp-border-color-item);
}

.item-frame {
    border-color: var(--lp-border-color-item);
}

.flex-card {
    border-color: var(--lp-border-color-item);
    --border-color: var(--lp-border-color-item);
}

.theme-aubergine-bg {
    background: var(--lp-color-negative-bg);
}

.theme-aubergine-bg-gradient {
    background: #{linear-gradient(rgba($lp-color-negative-bg, 0.8), rgba($lp-color-negative-bg, 0.5))};
}
