ion-icon {
    &[class*="leplace-"] {
        mask-size: contain;
        mask-position: 50% 50%;
        mask-repeat: no-repeat;
        background: currentColor;
        width: 1em;
        height: 1em;
    }

    &.normal {
        width: 1em;
        height: 1em;
    }

    &[class*="leplace-backpack"] {
        mask-image: url(../../assets/icons/backpack.svg);
    }

    &[class*="leplace-alarm-clock"] {
        mask-image: url(../../assets/icons/alarm-clock.svg);
    }

    &[class*="leplace-hourglass"] {
        mask-image: url(../../assets/icons/hourglass.svg);
    }

    &[class*="leplace-distance"] {
        mask-image: url(../../assets/icons/distance.svg);
    }

    &[class*="leplace-stopwatch"] {
        mask-image: url(../../assets/icons/stopwatch.svg);
    }

    &[class*="leplace-check-cool"] {
        mask-image: url(../../assets/icons/checked.svg);
    }

    &[class*="leplace-check"] {
        mask-image: url(../../assets/icons/check.svg);
    }

    &[class*="leplace-play"] {
        mask-image: url(../../assets/icons/press-play-button.svg);
    }

    &[class*="leplace-cancel"] {
        mask-image: url(../../assets/icons/cancel.svg);
    }

    &[class*="leplace-film"] {
        mask-image: url(../../assets/icons/film.svg);
    }

    &[class*="leplace-handshake"] {
        mask-image: url(../../assets/icons/hand-shake.svg);
    }

    &[class*="leplace-www"] {
        mask-image: url(../../assets/icons/grid-world.svg);
    }

    &[class*="leplace-qr"] {
        mask-image: url(../../assets/icons/qr-code.svg);
    }

    &[class*="leplace-google-maps-bw"] {
        mask-image: url(../../assets/icons/google-maps-bw.svg);
    }

    // &[class*="custom-qrcode"] {
    //   mask-image: url(../../assets/qrcode.svg);
    // }
    // &[class*="custom-spades"] {
    //   mask-image: url(../../assets/spades.svg);
    // }
}

.hud-icon {
    width: 0.7em !important;
}

.slot-icon-padding {
    padding-right: 5px;
}

.icon-left {
    margin-left: 0 !important;
}
