.item-level {
    height: 30px;
    padding-top: 6px;
}

.chat-scroll {
    height: 60px;
    overflow-y: scroll;
}

.chat-scroll-large {
    position: absolute;
    top: 60px;
    left: 0px;
    height: calc(100% - 60px);
    overflow-y: scroll;
}

.padding-chat-send {
    padding-left: 10px;
    padding-right: 50px;
}

.categories-height {
    // height: 76%;
    height: 95%;
}

.inventory-height {
    // height: 76%;
    height: 90%;
}

.item-slide-height {
    max-height: 160px;
}

.inventory-height-2 {
    // height: 76%;
    height: 70%;
    max-height: 250px;
}

.team-stats-height {
    height: calc(100% - 50px);
}

.story-data-height {
    height: calc(100vh - 300px);
}

.story-data-height-locked {
    height: calc(100vh - 270px);
}

.event-data-height {
    height: calc(100vh - 340px);
}

.gmap-data-height {
    height: calc(100vh - 200px);
}

.full-height {
    // // original:
    // height: 100vh;
    // // safari wants this:
    height: calc(100vh - 80px); // was 50px on android
    // height: calc(100vh - 60px); // was 50px on android
    margin-bottom: auto;
}

.full-height-web-ios {
    // // original:
    // height: 100vh;
    // // safari wants this:
    height: calc(100vh - 180px); // was 50px on android
    margin-bottom: auto;
}

.full-height-modal {
    // // original:
    // height: 100vh;
    // // safari wants this:
    height: calc(80vh - 120px); // was 50px on android
    // height: calc(100vh - 60px); // was 50px on android
    margin-bottom: auto;
}

.height-100 {
    height: 100%;
}

.half-height {
    height: 50vh;
}

.half-height-max {
    max-height: 50vh;
}

.height-40 {
    height: 40vh;
}

.height-20 {
    height: 20vh;
}

.half-width-rel {
    width: 50%;
}

.metrics-height {
    // height: 80%;
    height: calc(100vh - 180px);
}

.gmap-transp {
    background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==') !important;
    background: rgba(0, 0, 0, 0) url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==') !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

.capacitor-google-map {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.generic-chart-container {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

.test-chart-container {
    // width: 400px;
    // width: calc(100vw - 20px);
    // height: 400px;
    // height: calc(100vh - 40px);
    // min-height: 100%;
}

.sound-button {
    // max-height: calc(100vw - 100px);
    max-height: calc(100vh - 250px);
}

.item-overlay-height {
    height: 48px;
}
