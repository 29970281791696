.cursor-pointer {
    cursor: pointer;
}

// body {
//     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
// }

// :root {
//     --ion-font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
// }
