// ion-alert {}

// ion-alert .alert-wrapper {
//     -webkit-box-shadow: 0 8px 10px rgba(0, 0, 0, 0.4);
//     box-shadow: 0 8px 10px rgba(0, 0, 0, 0.4);

//     // background: var(--lp-color-primary-alert));
//     // background: rgba(0, 0, 0, 0);
//     background: var(--background-radial-gradient-matte);
//     color: var(--lp-color-light) !important;
//     border-style: solid;
//     border-color: var(--lp-border-color);
//     border-radius: var(--border-radius-global) !important;
//     border-width: var(--lp-border-width);
// }

ion-alert .alert-wrapper {
    -webkit-box-shadow: 0 8px 10px rgba(0, 0, 0, 0.4);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.4);

    // background: var(--lp-color-primary-alert));
    // background: rgba(0, 0, 0, 0);
    background: var(--background-radial-gradient-matte) !important;
    color: var(--lp-color-light) !important;
    border-style: solid;
    border-color: var(--lp-border-color);
    border-radius: var(--border-radius-global) !important;
    border-width: var(--lp-border-width);
}

ion-alert .alert-message {
    color: var(--lp-color-primary-text) !important;
}

ion-alert .alert-wrapper p {
    color: var(--lp-color-primary-text) !important;
}

ion-alert .alert-wrapper .alert-head .alert-title {
    // font-style: italic;
    color: var(--lp-color-primary-text) !important;
    font-weight: bold;
}

ion-alert .alert-input {
    color: var(--lp-color-primary-text) !important;
}

// ion-alert .alert-radio-label {
//     color: var(--lp-color-light)) !important;
// }

ion-alert .alert-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    // color: var(--lp-color-light)) !important;
    color: var(--lp-color-primary-text) !important;
    opacity: 0.5; /* Firefox */
}

ion-alert .alert-wrapper .alert-head .alert-sub-title {
    //font-style: italic;
    font-size: 18px;
    color: var(--lp-color-primary-text) !important;
}

ion-alert .alert-wrapper .alert-button-group .alert-button {
    color: var(--lp-color-primary-text) !important;
    font-size: 16px;
    font-weight: bold;
}

ion-alert .alert-radio-label {
    font-size: 16px;
    color: var(--lp-color-primary-text) !important;
}

ion-alert .alert-radio-icon {
    border-color: var(--lp-color-primary-text) !important;
}

ion-alert[aria-checked="true"] .alert-radio-icon {
    border-color: var(--lp-color-primary-text) !important;
}

ion-alert .alert-radio-inner {
    background: var(--lp-color-primary-text) !important;
}

ion-alert.alert-input::placeholder {
    color: var(--lp-color-primary-text) !important;
    opacity: 0.5;
}

// alert

.alert-md,
.alert-ios {
}

.alert-md .alert-wrapper,
.alert-ios .alert-wrapper {
    -webkit-box-shadow: 0 8px 10px rgba(0, 0, 0, 0.4);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.4);
    background: var(--background-radial-gradient-matte);

    // background: var(--lp-color-primary-alert));
    // background: rgba(0, 0, 0, 0);
    color: var(--lp-color-light) !important;
    border-style: solid;
    border-color: var(--lp-border-color);
    border-radius: var(--border-radius-global);
    border-width: var(--lp-border-width);
}

.alert-md .alert-wrapper .alert-head .alert-title,
.alert-ios .alert-wrapper .alert-head .alert-title {
    // font-style: italic;
    color: var(--lp-color-primary-text) !important;
    font-weight: bold;
    font-weight: bold;
}

.alert-md .alert-input,
.alert-ios .alert-input {
    color: var(--lp-color-primary-text) !important;
}

.alert-md .alert-radio-label,
.alert-ios .alert-radio-label {
    font-size: 16px;
    color: var(--lp-color-primary-text) !important;
}

.alert-md .alert-radio-icon,
.alert-ios .alert-radio-icon {
    border-color: var(--lp-color-primary-text) !important;
}

.alert-md[aria-checked="true"] .alert-radio-icon,
.alert-ios[aria-checked="true"] .alert-radio-icon {
    border-color: var(--lp-color-primary-text) !important;
}

.alert-md .alert-radio-inner,
.alert-ios .alert-radio-inner {
    // border-color: var(--lp-color-light)) !important;
    background: var(--lp-color-primary-text) !important;
}

.alert-md .alert-input::placeholder,
.alert-ios .alert-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--lp-color-primary-text) !important;
    opacity: 0.5; /* Firefox */
}

.alert-md .alert-wrapper .alert-head .alert-sub-title,
.alert-ios .alert-wrapper .alert-head .alert-sub-title {
    //font-style: italic;
    font-size: 18px;
}

.alert-md .alert-wrapper .alert-button-group .alert-button,
.alert-ios .alert-wrapper .alert-button-group .alert-button {
    color: var(--lp-color-primary-text) !important;
    font-size: 16px;
    font-weight: bold;
}

.toast-custom-class {
    // --color: var(--lp-color-primary-text);
    // --border-color: var(--lp-border-color-item);
    // --border-radius: var(--border-radius-inner-button);
    // --border-style: solid;
    // --border-width: var(--border-outset-button);
    // --background: var(--lp-color-primary-70);
    // --button-color: var(--lp-color-primary-text);
    --color: var(--lp-color-alternate-text);
    --border-color: var(--lp-border-color-item);
    --border-radius: var(--border-radius-inner-button);
    --border-style: solid;
    --border-width: var(--border-outset-button);
    --background: var(--lp-color-alternate-70);
    --button-color: var(--lp-color-alternate-text);

    // font-size: var(--font-size-m);
    // .toast-wrapper {
    //     height: 32px !important;
    //     bottom: 64px !important;
    // }
    // .toast-button {
    //     font-size: var(--font-size-m) !important;
    // }
}

.toast-center {
    text-align: center !important;
}
