:root {
    --xxsmall: 2px;
    --xsmall: 5px;
    --small: 10px;
    --medium: 20px;
    --large: 40px;
}

.self-center {
    align-self: center;
}

.center-text {
    text-align: center;
}

.center-all {
    justify-content: center;
    text-align: center;
    align-items: center;
}

.center-items {
    align-items: center;
}

.vertical-center-modal-content {
    display: flex;
    align-items: center;
    height: 80%
}

.slide-center {
    .swiper-wrapper {
        align-items: center;
    }
}

.slide-avatar {
    .swiper-wrapper {
        .swiper-slide {
            img {
                width: 20px;
                border-radius: 50%;
                object-fit: cover;
                height: 20px;
            
                .img {
                    border-radius: 50%;
                }
            }
        }
    }
}

.center-flex-auto {
    margin-left: auto;
    margin-right: auto;
}

.center-flex {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.center-input-container {
    display: table;
    margin: 0 auto;
}

.dispblock {
    display: block;

    // white-space: pre;
    // word-wrap: break-word;
}

.border-radius {
    border-radius: var(--border-radius-global);
}

.border-radius-img {
    border-radius: var(--border-radius-global);

    .img {
        border-radius: var(--border-radius-global);
    }
}

.border-btm-radius {
    border-bottom-left-radius: var(--border-radius-global);
    border-bottom-right-radius: var(--border-radius-global);
}

.border-top-radius {
    border-top-left-radius: var(--border-radius-global);
    border-top-right-radius: var(--border-radius-global);
}

// padding

.no-padding {
    padding: 0px !important;
}

.list-padding {
    margin-top: var(--lp-item-padding);

    // --inner-padding-start: var(--lp-item-padding);
    --inner-padding-end: var(--lp-item-padding);
    --padding-start: 0px;
}

.list-padding-top {
    margin-top: var(--lp-item-padding);
}

.list-padding-top-inner {
    margin-top: 4px !important;
}

.list-item-padding-top {
    margin-top: var(--lp-first-item-padding);
}

.padding-xs {
    padding: var(--xsmall) !important;
}

.pading-xxs {
    padding: var(--xxsmall) !important;
}

.padding-null {
    padding: 0px !important;
}

.padding-s {
    padding: var(--small) !important;
}

.padding-m {
    padding: var(--medium) !important;
}

.padding-keyboard {
    padding-bottom: 200px;
}

.padding-horizontal-s {
    padding-left: var(--small);
    padding-right: var(--small);
}

.padding-vertical-s {
    padding-top: var(--small);
    padding-bottom: var(--small);
}

.padding-vertical-none {
    padding-top: 0px;
    padding-bottom: 0px;
}

.grid-center-absolute {
    position: absolute;
}

.padding-right-s {
    padding-right: var(--small) !important;
}

.padding-right-m {
    padding-right: var(--medium) !important;
}

.padding-left-s {
    padding-left: var(--small) !important;
}

.padding-left-none {
    padding-left: 0px !important;
}

.padding-bottom-none {
    padding-bottom: 0px !important;
}

.padding-bottom-s {
    padding-bottom: var(--small) !important;
}

.padding-bottom-m {
    padding-bottom: var(--medium) !important;
}

.padding-bottom-xs {
    padding-bottom: var(--xsmall) !important;
}

.padding-top-s {
    padding-top: var(--small) !important;
}

.padding-top-none {
    padding-top: 0px !important;
}

.padding-top-m {
    padding-top: var(--medium) !important;
}

.padding-horizontal-m {
    padding-left: var(--medium);
    padding-right: var(--medium);
}

.padding-top-xs {
    padding-top: var(--xsmall) !important;
}

.padding-top-none {
    padding-top: 0px !important;
}

// margin

.margin-horizontal-xs {
    margin-left: var(--xsmall);
    margin-right: var(--xsmall);
}

.margin-horizontal-s {
    margin-left: var(--small);
    margin-right: var(--small);
}

.margin-vertical-s {
    margin-top: var(--small);
    margin-bottom: var(--small);
}

.margin-vertical-none {
    margin-top: 0px;
    margin-bottom: 0px;
}

.margin-right-s {
    margin-right: var(--small) !important;
}

// margin top
.margin-top-none {
    margin-top: 0px !important;

    .fixed-content {
        margin-top: 0px !important;
    }

    .scroll-content {
        margin-top: 0px !important;
    }
}

.margin-top {
    margin-top: 50px !important;
}

.margin-top-bar {
    margin-top: 5px !important;
}

.margin-top-80 {
    margin-top: 80px !important;
}

.margin-s {
    margin: var(--small) !important;
}

.margin-top-s {
    margin-top: var(--small) !important;
}

.margin-left-s {
    margin-left: var(--small) !important;
}

.margin-top-xs {
    margin-top: var(--xsmall) !important;
}

.margin-top-chat {
    margin-top: 80px !important;
}

// margin bottom

.margin-bottom-50 {
    margin-bottom: 50px !important;
}

.margin-bottom-s {
    margin-bottom: var(--small) !important;
}

.margin-bottom-xs {
    margin-bottom: var(--xsmall) !important;
}

.margin-bottom-none {
    margin-bottom: 0px !important;
}

.margin-bottom-home {
    // margin-bottom: var(--xsmall) !important;
}

.margin-top-home {
    // margin-top: var(--xsmall) !important;
}

// other

.full-w {
    width: 100%;
}

.full-w-imp {
    width: 100% !important;
    max-width: 100% !important;
}

.vgauge-size {
    width: 60px;

    // height: 200px;
    // height: 60vh;
    height: 100%;
}

.vgauge-size-inner {
    .ngx-charts {
        width: 60px !important;
    }
}

.width-m {
    width: 70vw;
    margin: 0 auto;
}

.flip-horizontal {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    -ms-filter: fliph; /*IE*/
    filter: fliph; /*IE*/
}

.center-element {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.stick-bottom {
    position: absolute;

    // position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.stick-bottom-fullscreen {
    position: absolute;

    // position: sticky;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 9999;
}

.stick-bottom-fixed {
    // ios fix
    position: fixed;

    // position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.stick-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.stick-top-right {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
}

.stick-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
}

.center-self {
    align-self: center;
}

.center-content {
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.no-border {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
}

.no-border-width {
    border-width: 0px !important;
}

.flex-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.flex-row-x {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.border-frame {
    // border-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    border-width: var(--lp-border-width);
    border-color: var(--lp-color-primary-item);
    border-radius: 18px;
    // padding: 6px;
    margin: 6px;
}

.input-wrapper-item-colorful {
    --highlight-height: 2px;
    --highlight-color-focused: #43e7f3;
    --highlight-color-valid: #6f58d8;
    --highlight-color-invalid: #ff46be;
}

.input-wrapper-item-none {
    --highlight-height: 0px;
}

.block {
    display: block;
}