.img-zoomable-div {
    //  height: 100%;
    //  width: 100%;
    background-size: cover;
    background-position: center;
    transition: all 0.5s ease;

    //  background-image: url('foo.jpg');
}

.img-zoomable-div:hover {
    transform: scale(1.5);
}

.img-zoomable-div:active {
    transform: scale(1.5);
}

// #btnControl {
//     display: none;
// }

// #btnControl:checked + label {
//     width: 70px;
//     height: 74px;
//     line-height: 74px;
// }

.img-full {
    max-height: 100%;
    max-width: 100%;
    border: 0;
}

.img-small {
    max-height: 200px;

    // max-height: 40%;
    max-width: 100%;
    border: 0;
}

.img-small-fixed-container {
    height: 220px;
}

.img-xs {
    max-height: 100px;
    max-width: 100%;
    border: 0;
}

.img-xxs {
    max-height: 60px;
    max-width: 100%;
    border: 0;
}

.zoom-btn {
    font-size: 2rem;
    position: absolute;
    // top: 5px;
    right: 5px;
}

.upload-btn {
    font-size: 2rem;
    position: absolute;
    // top: 5px;
    left: 5px;
}

.img-place-header {
    height: auto;
    width: 120px;

    // border-radius: 50%;
    border-radius: var(--border-radius-global);

    .img {
        // border-radius: 50%;
        border-radius: var(--border-radius-global);
    }
}

.img-avatar-header-large {
    height: auto;
    width: 200px;

    // border-radius: 50%;
    border-radius: var(--border-radius-global);

    .img {
        // border-radius: 50%;
        border-radius: var(--border-radius-global);
    }
}

.img-avatar-header {
    height: auto;
    width: 120px;

    // border-radius: 50%;
    border-radius: var(--border-radius-global);

    .img {
        // border-radius: 50%;
        border-radius: var(--border-radius-global);
    }
}


.img-avatar-large-crop {
    width: 120px;
    border-radius: 25%;
    object-fit: cover;
    height: 120px;

    .img {
        border-radius: 50%;
    }
}

.img-avatar-large {
    height: auto;
    width: 120px;
    border-radius: 25%;

    .img {
        border-radius: 50%;
    }
}

.img-avatar-crop {
    width: 80px;
    border-radius: 50%;
    object-fit: cover;
    height: 80px;

    .img {
        border-radius: 50%;
    }
}

.img-avatar {
    height: auto;
    width: 80px;
    border-radius: 50%;

    .img {
        border-radius: 50%;
    }
}

.img-avatar-small-crop {
    width: 40px !important;
    border-radius: 50%;
    object-fit: cover;
    height: 40px !important;

    .img {
        border-radius: 50%;
    }
}

.img-avatar-small {
    height: auto;
    width: 40px !important;
    border-radius: 50%;

    .img {
        border-radius: 50%;
    }
}

.img-avatar-xsmall-crop {
    width: 20px !important;
    border-radius: 50%;
    object-fit: cover;
    height: 20px !important;

    .img {
        border-radius: 50%;
    }
}

.img-avatar-xsmall {
    height: auto;
    width: 20px !important;
    border-radius: 50%;

    .img {
        border-radius: 50%;
    }
}

.img-avatar-25-crop {
    // height: auto;
    width: 80px;
    border-radius: 25%;
    object-fit: cover;
    height: 80px;

    .img {
        border-radius: 25%;
    }
}

.img-avatar-25 {
    height: auto;
    width: 80px;
    border-radius: 25%;

    .img {
        border-radius: 25%;
    }
}

.image-border {
    opacity: 0.7;
    max-width: 100%;
    max-height: 200px !important;
    height: auto;
    width: auto;
    border-radius: var(--border-radius-global);
}

.profile-picture {
    border-radius: 50%;
    // border-radius: 20px;
    border-style: outset;
    border-color: var(--lp-color-primary);
    border-width: 3px;
}
