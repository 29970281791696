:root {
    // overrides
    --ion-toolbar-background: #{linear-gradient(
            to right,
            rgba($lp-color-bg, 0.6),
            rgba($lp-color-bg, 0.4),
            rgba($lp-color-bg, 0.6)
        )};
    --ion-background-color: var(--lp-color-negative-bg);
    --ion-text-color: var(--lp-color-primary-text);

    ion-app.platform-ios12 ion-content {
        pointer-events: auto; /*fix scroll lock on ios12.2*/
    }

    .ion-split-pane-bg {
        background: var(--ion-background-color) !important;
        background-color: var(--ion-background-color) !important;
    }

    .ion-content-gradient {
        --background: var(--background-radial-gradient-matte);
    }

    .ion-header-gradient {
        --background: var(--background-radial-gradient-matte);
    }

    --ion-toolbar-min-height: 50px;

    ion-toolbar {
        --min-height: 50px;
        height: 50px;
    }

    ion-item {
        --background: rgba(0, 0, 0, 0);
        --border-color: rgba(0, 0, 0, 0);
        --padding-start: var(--lp-item-padding);
        --inner-padding-end: var(--lp-item-padding);
    }

    ion-list {
        background: rgba(0, 0, 0, 0);
    }

    ion-label {
        width: 100%;

        // --background: rgba(0,0,0,0);
    }

    // ion-col {
    //     padding: 2px;
    // }

    ion-title {
        font-weight: bold;
    }

    ion-fab-button {
        --background: none;
        --box-shadow: none;
        margin: var(--lp-item-padding);
    }

    ion-button {
        --box-shadow: none;
        --border-radius: var(--border-radius-inner-button);
        --border-color: var(--lp-border-color-item);

        // border-style: solid !important;
        // --border-width: var(--border-outset-button) !important;
        --border-width: 0px !important;
        font-weight: bold;
    }

    ion-button.button-disabled {
        opacity: 0.4;
    }

    ion-content {
        overflow: hidden;
        --overflow: hidden;
    }

    ion-checkbox {
        --background: rgba(0, 0, 0, 0);
        --background-checked: rgba(0, 0, 0, 0);
        --border-color: #{rgba($lp-color-text-3, 0.5) !important};
        --border-color-checked: var(--lp-color-primary-text) !important;
        --checkmark-color: var(--lp-color-primary-text) !important;
        --border-radius: 50%;
    }

    ion-radio {
        --color: #{rgba($lp-color-text-3, 0.5) !important};
        --color-checked: var(--lp-color-primary-text) !important;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    // ion-modal.stack-modal {
    //     --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    //     --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    // }

    .ion-input-dash {
        border-bottom-style: dashed;
        /* border-radius: 10px; */
        border-color: white;
        border-width: 1px;
    }
}
