.slide-image-inner-modal {
    max-height: 30vh;
    border-radius: 20px;
}

.leplace-slider {
    .toolbar-background {
        background: transparent;
        border-color: transparent;
    }

    .slide-zoom {
        height: 100%;
        background-color: var(--lp-color-bg);
    }

    .slide-title {
        // margin-top: 2.8rem;
        font-size: var(--font-size-mlg);
        font-weight: bold;
        margin-top: 2px;
    }

    .slide-text {
        font-size: 1em;
    }

    .slide-image {
        max-height: 40vh;
        border-radius: 20px;

        // max-width: 80%;
        // border-radius: 10px;
    }

    .slider-container {
        width: 100%;

        // height: 100%;

        height: calc(100vh - 80px);

        .swiper-pagination-bullets {
            top: 0px;
            left: 0;
            width: 100%;
        }

        .swiper-pagination-bullet-active {
            background: var(--lp-color-action-2);
        }

        .swiper-pagination-bullet {
            // background: var(--lp-color-primary-item);
            background: var(--lp-color-action-3);
        }
    }

    .slider-element {
        // height: 100%;
        // height: calc(100vh - 180px);

        text-align: center;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    ion-slide {
    }

    ion-slides {
        --bullet-background: var(--lp-color-action-3);

        // --bullet-background: var(--lp-color-primary-item);
        --bullet-background-active: var(--lp-color-action-3);
        border-radius: var(--border-radius-global);
    }

    ion-toolbar {
        --background: rgba(0, 0, 0, 0);
    }

    ion-header {
        --background: rgba(0, 0, 0, 0);
    }
}

.leplace-slider-modal {
    .toolbar-background {
        background: transparent;
        border-color: transparent;
    }

    .slide-zoom {
        height: 100%;
        background-color: var(--lp-color-bg);
    }

    .slide-title {
        // margin-top: 2.8rem;
        font-size: var(--font-size-mlg);
        font-weight: bold;
        margin-top: 2px;
    }

    .slide-text {
        font-size: 1em;
    }

    .slide-image {
        height: 25vh;
        max-height: 25vh;
        border-radius: 10px !important;
    }

    .slide-height-button {
        height: calc(80vh - 180px);
    }

    .slide-height {
        height: calc(80vh - 100px);
    }

    .slider-container {
        width: 100%;

        // height: 100%;

        height: calc(80vh - 140px);

        .swiper-pagination-bullets {
            top: 0px;
            left: 0;
            width: 100%;
        }

        .swiper-pagination-bullet-active {
            background: var(--lp-color-action-2);
        }

        .swiper-pagination-bullet {
            // background: var(--lp-color-primary-item);
            background: var(--lp-color-action-3);
        }
    }

    .slider-element {
        // height: 100%;
        // height: calc(100vh - 180px);
        height: calc(80vh - 200px);
        text-align: center;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide {
        justify-content: start;
    }

    ion-slide {
    }

    ion-slides {
        --bullet-background: var(--lp-color-action-3);

        // --bullet-background: var(--lp-color-primary-item);
        --bullet-background-active: var(--lp-color-action-3);
        border-radius: var(--border-radius-global);
    }

    ion-toolbar {
        --background: rgba(0, 0, 0, 0);
    }

    ion-header {
        --background: rgba(0, 0, 0, 0);
    }
}

// .slide-image {
//     max-height: 40vh;
//     border-radius: 20px;

//     // max-width: 80%;
//     // border-radius: 10px;
// }

// .slide-image-tutorial {
//     max-height: 30%;
//     max-width: 60%;

//     // min-height: 200px;
//     // min-height: 40%;
//     // max-height: 100%;
//     // max-width: 100%;
//     // width: 100%;
//     // margin: 36px 0;
//     border-radius: 10px;
// }

.slider-tutorial-bullets {
    .swiper-pagination-bullet-active {
        background: var(--lp-color-action-2);
    }

    .swiper-pagination-bullet {
        background: var(--lp-color-primary-item);
    }
}

.slider-primary-bullets {
    .swiper-pagination-bullet-active {
        background: var(--lp-color-action-3);
    }

    .swiper-pagination-bullet {
        background: var(--lp-color-action-3);
    }
}

.slider-container-light {
    width: 100%;

    .swiper-pagination-bullets {
        top: 0px;
        left: 0;
        width: 100%;
    }

    .swiper-pagination-bullet-active {
        background: rgba(0, 0, 0, 0);
    }

    .swiper-pagination-bullet {
        background: rgba(0, 0, 0, 0);
    }
}
