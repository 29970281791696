.filters {
    ion-col {
        text-align: center;
        font-size: 1.6rem;
        line-height: 1.6rem;

        ion-icon {
            color: #ccc;
        }

        &.col-with-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    p {
        color: #fff;
        margin: 0;
        font-size: 1.6rem;
        line-height: 1.6rem;
    }

    .selected {
        font-weight: 700;
    }
}

.slider-controls {
    ion-col {
        text-align: center;
        font-size: 1.6rem;
        line-height: 1.6rem;

        .control-icon {
            color: #ccc;
        }

        &.col-with-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.action-button-fab {
    border-radius: var(--border-radius-inner-button);
    height: 3em;
    min-height: 3em;
    width: auto;
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button {
    height: var(--action-button-height);

    // min-height: 4em;
    width: 10em;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.menu-button {
    height: 48px;
}

.options-menu-button {
    .span-icon {
        width: 100%;
        margin-left: -24px;
    }

    .icon-wrapper {
        margin-right: auto;
    }
}

.action-button-tabs-slim {
    height: 24px;

    // min-height: 4em;
    // width: 80px;
    width: 120px;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-global);
    font-size: 10px;
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-tabs {
    height: 40px;

    // min-height: 4em;
    // width: 80px;
    width: 90%;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-global);
    font-size: 10px;
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-tabs-icon {
    height: 40px;
    width: 60px;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-global);
    font-size: 10px;
}

.action-button-fill-xs {
    height: 28px;

    // min-height: 30px;
    // width: 99.5%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);

    // border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-fill-xxs {
    height: 12px;

    // min-height: 30px;
    // width: 99.5%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);

    // border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-xs {
    height: 36px;
    width: 36px;
    border-radius: var(--border-radius-global);

    // border-style: solid !important;
    // border-width: var(--border-outset-button) !important;
}

.action-button-paging {
    height: var(--action-button-height);
    width: 48px;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-global);

    // border-style: solid !important;
    // border-width: var(--border-outset-button) !important;
}

.action-button-icon-only {
    margin: 0px;
    padding: 0px;
    height: 24px;
    border-radius: var(--border-radius-global);
    // width: 24px;
    width: 100%;
}

.action-button-expand {
    height: 25px;
    width: 25px;
    font-size: 8px !important;
    font-weight: bold;
    border-radius: 50%;
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-expand-wide {
    height: 25px;
    width: 100%;
    font-size: 8px !important;
    font-weight: bold;
    border-radius: var(--border-radius-inner-button);
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-wide {
    height: var(--action-button-height);
    width: 16em;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-border {
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-fill {
    height: var(--action-button-height);

    // min-height: 30px;
    // width: 99.5%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.button-rounded {
    border-radius: var(--border-radius-global) !important;
}

.button-border {
    border-style: solid !important;
    border-width: var(--lp-border-width) !important;
}

.action-button-fill-large {
    height: var(--action-button-height);

    // width: 99.5%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.action-button-h {
    height: var(--action-button-height);
    border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-inner-button);
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
}

.google-button-container {
    margin: 4px 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.round-fab {
    // border-radius: 10px !important;
    // opacity: 0.9;
    border-color: var(--lp-border-color-item);

    // border-radius: var(--border-radius-global) !important;
    border-radius: 100% !important;
    border-style: solid !important;
    border-width: var(--border-outset-button) !important;
    --border-color: var(--lp-border-color-item);

    // border-radius: var(--border-radius-global) !important;
    --border-radius: 100% !important;
    --border-style: solid !important;
    --border-width: var(--border-outset-button) !important;
}

.round-fab-size {
    margin: 8px;
    width: 50px !important;
    height: 50px !important;
}

.round-fab-size-huge {
    margin: 8px;
    width: 80px !important;
    height: 80px !important;
}

.button-60 {
    // background: var(--lp-color-primary);
    border: none;

    // margin: 5px;
    // padding: 5px;
    // font-weight: bold;

    // font-style: italic;
    height: 60px;

    // min-height: 4em;
    // width: 12em;
    width: 60%;

    // border-width: 0px !important;
    // border-color: var(--lp-border-color-item);
    border-radius: var(--border-radius-outer-button);

    // border-radius: 20px;
    // --border-radius: 17px;

    // border-style: solid !important;
    // border-width: var(--border-outset-button) !important;
}

.as-text {
    background: none;
    border: none;
    margin: 5px;
    padding: 5px;
    height: 40px;
    font-weight: bold;
    --background: rgba(0, 0, 0, 0);
    --border-width: 0px;
    --border-style: none;
    --padding-start: 5px;
    --padding-end: 5px;
    --padding-top: 5px;
    --padding-bottom: 5px;
    --box-shadow: none;
}

.button-props {
    cursor: pointer;
}

.button-props:active {
    transform: scale(0.98);
    /* Scaling button to 0.98 to its original size */
    // box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    /* Lowering the shadow */
    border-radius: var(--border-radius-global-button);
}

.menu-button-size {
    min-height: 48px;
    min-width: 48px;
    width: 48px;
}

.menu-button-size-right {
    // width: 54px;
}

.button-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.button-grey {
    opacity: 0.5;
}
