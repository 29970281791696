/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* bootstrap may overlap with ionic styles */
// @import "~bootstrap/dist/css/bootstrap.css";

/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */

// copy fonts to www/assets/fonts

@font-face {
    font-family: "leplace";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: "leplace";
    font-style: normal;
    font-weight: bold;
    src: url("./assets/fonts/Roboto-Bold.ttf");
}

@font-face {
    font-family: "leplace";
    font-style: italic;
    font-weight: normal;
    src: url("./assets/fonts/Roboto-RegularItalic.ttf");
}

@font-face {
    font-family: "leplace";
    font-style: italic;
    font-weight: bold;
    src: url("./assets/fonts/Roboto-BoldItalic.ttf");
}

@font-face {
    font-family: "harlow";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/HarlowSolidItalicItalic.ttf");
}

// @font-face {
//     font-family: 'leplace';
//     font-style: normal;
//     font-weight: normal;
//     src: url("./assets/fonts/CroissantOne-Regular.ttf");
// }

// @font-face {
//     font-family: 'leplace';
//     font-style: normal;
//     font-weight: bold;
//     src: url("./assets/fonts/CroissantOne-Regular.ttf");
// }

// @font-face {
//     font-family: 'leplace';
//     font-style: italic;
//     font-weight: normal;
//     src: url("./assets/fonts/CroissantOne-Regular.ttf");
// }

// @font-face {
//     font-family: 'leplace';
//     font-style: italic;
//     font-weight: bold;
//     src: url("./assets/fonts/CroissantOne-Regular.ttf");
// }

// html {
//     min-height: calc(100% + env(safe-area-inset-top));
//     padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
// }

//  @import "./theme/variables";
// variables
@import "./theme/variables.scss";
// global
@import "./app/app.scss";
@import "./theme/gen/theme-global.scss";
// format
@import "./theme/format/buttons-format.scss";
@import "./theme/format/buttons.scss";
@import "./theme/format/cards.scss";
@import "./theme/format/elements-custom-format.scss";
@import "./theme/format/elements-custom.scss";
@import "./theme/format/elements-format.scss";
@import "./theme/format/elements-style.scss";
@import "./theme/format/elements.scss";
@import "./theme/format/footer.scss";
@import "./theme/format/flex.scss";
@import "./theme/format/frame.scss";
@import "./theme/format/header.scss";
@import "./theme/format/h-plate.scss";
@import "./theme/format/hud.scss";
@import "./theme/format/icons.scss";
@import "./theme/format/image-bg.scss";
@import "./theme/format/gradient-bg.scss";
@import "./theme/format/image.scss";
@import "./theme/format/list.scss";
@import "./theme/format/material.scss";
@import "./theme/format/modal.scss";
@import "./theme/format/alert.scss";
@import "./theme/format/overrides.scss";
@import "./theme/format/progress-bar.scss";
@import "./theme/format/scrollbar.scss";
@import "./theme/format/slider.scss";
@import "./theme/format/table.scss";
@import "./theme/format/text-custom-elements.scss";
@import "./theme/format/text-format.scss";
@import "./theme/format/text-icons.scss";
@import "./theme/format/text-style.scss";
@import "./theme/format/toolbar.scss";
@import "./theme/format/tutorials.scss";
@import "./theme/format/ionic-styles.scss";
@import "./theme/format/bootstrap.scss";
// colors
@import "./theme/colors/buttons.scss";
@import "./theme/colors/icons.scss";
@import "./theme/colors/text-overlay.scss";
@import "./theme/colors/text.scss";
